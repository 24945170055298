import * as React from "react";
import { IconProps } from "..";

const Tiktok = React.forwardRef<SVGSVGElement, IconProps>(
  ({ title = "tiktok", titleId = "tiktok", ...props }, svgRef) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <g clipPath="url(#prefix__clip0_370_55352)" fill="currentColor">
          <path d="M19.73 7.321v2.556a7.838 7.838 0 01-4.58-1.489v6.714a6.075 6.075 0 01-6.074 6.062A6.011 6.011 0 015.6 20.065a6.062 6.062 0 0010.503-4.126V9.244a7.852 7.852 0 004.586 1.47v-3.29a4.685 4.685 0 01-.958-.103z" />
          <path d="M15.15 15.102V8.388a7.837 7.837 0 004.587 1.47V7.301a4.58 4.58 0 01-2.504-1.47 4.575 4.575 0 01-2.012-3.002h-2.415v13.224a2.773 2.773 0 01-5.014 1.514 2.772 2.772 0 011.277-5.226c.278.003.553.046.818.128V9.915a6.056 6.056 0 00-4.293 10.22 6.01 6.01 0 003.482 1.03 6.075 6.075 0 006.075-6.063z" />
          <path d="M17.233 5.833a4.548 4.548 0 01-1.13-3.003h-.882a4.574 4.574 0 002.012 3.003zM9.076 12.33a2.772 2.772 0 00-1.278 5.225 2.766 2.766 0 012.242-4.389c.278.004.553.047.818.128v-3.36a5.792 5.792 0 00-.818-.064h-.147v2.555a2.838 2.838 0 00-.817-.095z" />
        </g>
        <defs>
          <clipPath id="prefix__clip0_370_55352">
            <path
              fill="currentColor"
              transform="translate(2 2)"
              d="M0 0h20v20H0z"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
);
export default Tiktok;
